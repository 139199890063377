import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Quicklinks from '../../../components/Quicklinks';
import Image from '../../../components/Image';
import Icon from '../../../components/Icon';
import Video from '../../../components/Video';

const HowDoWeBreathe = () => (
  <Layout
    title="How Do We Breathe - Science at Home"
    className="science-starters has-video"
  >
    {/* Intro Title and Video */}
    <Section className="science-starters__video">
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../getting-started">Science Starters</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../science-at-home">Science at Home</Link>
          </li>
          <li className="breadcrumb active">How Do We Breathe</li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">How Do We Breathe</h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <Link to="../soap-boat" className="previous">
                <Icon name="arrowleftgray" />
              </Link>
              <Video
                guidSrc="0d475876-c432-4fc6-b618-7fb32ef8216d"
                className="de-vid"
              ></Video>
              <Link to="../bernoulli-balance" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Main Content */}
    <Section className="pt-0 pb-6">
      <Container>
        <Row className="mb-4">
          <Column size={8} offset={1}>
            <h2 className="science-at-home__intro">
              How do our lungs work? Follow along as special guest Dakota
              Dozier, an offensive guard for the Minnesota Vikings, makes a
              model to show how air flows in and out of the lungs with ease.
            </h2>
          </Column>
        </Row>
        <Row>
          <Column size={8} offset={1} className="science-home__content">
            {/* Quick Links */}
            <Quicklinks
              className="pl-0"
              title="Quicklinks:"
              smoothScroll
              links={[
                {
                  target: '#background',
                  label: 'Background',
                },
                {
                  target: '#key-concepts',
                  label: 'Key Concepts',
                },
                {
                  target: '#materials',
                  label: 'Materials',
                },
                {
                  target: '#safety',
                  label: 'Safety',
                },
                {
                  target: '#standards',
                  label: 'Standards',
                },
              ]}
            />
            {/* Background */}
            <h3 id="background">Background</h3>
            <p>
              We breathe a lot—roughly 10 times a minute! Have you ever wondered
              how the process of breathing works so smoothly? Our lungs allow us
              to inhale the oxygen our body needs, but they do much, much more.
              They also allow us to get rid of carbon dioxide, the waste product
              created in the body, and they play a vital role in singing,
              shouting and even giggling. In this activity you will make a model
              of a lung and use it to discover how air flows in and out of the
              lungs with ease.
            </p>
            {/* Key Concepts */}
            <h3 id="key-concepts">Key Concepts</h3>
            <ul>
              <li>Biology</li>
              <li>The body</li>
              <li>Physics</li>
              <li>Air pressure</li>
            </ul>
            {/* Materials */}
            <h3 id="materials">Materials</h3>
            <ul>
              <li>
                Disposable empty transparent bottle (16 fluid ounces) made of
                hard plastic (such as a sports drink bottle)
              </li>
              <li>Ruler</li>
              <li>Two balloons (8-inch balloons work well)</li>
              <li>
                Utility knife (have an adult help and use caution when using the
                knife)
              </li>
              <li>Adult helper</li>
              <li>Scissors</li>
              <li>Drinking straw (optional)</li>
              <li>Modeling clay (optional)</li>
              <li>Tape (optional)</li>
              <li>Additional balloon (optional)</li>
            </ul>
            <h3>Preparation</h3>
            <ul>
              <li>
                Ask an adult to cut the plastic bottle. Cut off the bottle's
                bottom so that when a balloon hangs inside the bottle from the
                spout there is about 1/3 to 3/4 of an inch of empty space below
                the balloon.
              </li>
              <li>
                Place the cut bottle down on the wide opening. Lower a balloon
                into the bottle until only part of the balloon's neck sticks
                out. Fold the neck of the balloon over the top of the bottle.
                The balloon represents a lung.
              </li>
              <li>
                Turn the bottle over (keeping the balloon inside) so the bottle
                top rests on the table. In the next steps you will create and
                add the diaphragm to your model.
              </li>
              <li>
                Make a knot in the neck of the second balloon. At the opposite
                side of this balloon cut off about a third of the balloon so you
                are left with a wide opening.
              </li>
              <li>
                Stretch the wide opening of the cut balloon over the wide
                opening of the bottle. Pull the edges of the balloon far enough
                up the bottle so the balloon surface is gently stretched. Make
                sure that the knot is on the outside and located near the middle
                of the bottle opening.
              </li>
              <li>
                Like an inflated balloon our lungs are full of air. We have two
                lungs, which are enclosed in the ribcage and protected by 24
                ribs. When you breathe in, air flows into your lungs. When you
                breathe out, air flows out of your lungs. The balloon inside the
                bottle is like one of your lungs. The bottle is like your
                ribcage.
              </li>
              <li>
                Hold the bottle so you can see the balloon inside (representing
                the lung). Gently pull down on the knot. What happens to the
                balloon inside the bottle?
              </li>
              <li>
                Let the knot come back to its neutral position and then gently
                push it in. What happens to the balloon inside the bottle now?
              </li>
              <li>
                Repeat these steps a few times. Does this resemble breathing?
                Why?
              </li>
              <li>
                Which part resembles breathing in and which part resembles
                breathing out?
              </li>
              <li>
                If your model is working well, air will rush into the balloon
                when you pull the knot outward and flow out when you push the
                knot inward. Why do you think this happens?
              </li>
              <li>
                When we breathe in a relaxed way our diaphragmâ€”the muscle that
                separates the chest cavity from the abdominal cavityâ€”moves to
                expand and contract the chest cavity. How is that similar to
                what you do with your model?
              </li>
              <li>
                Push and pull the knot a few more times. Using the model can you
                find which movement of the diaphragm creates inhalation and
                which creates exhalation?
              </li>
              <li>
                Feel your ribs and breathe in deeply then exhale. Can you feel
                your ribcage expand and fall back?
              </li>
              <li>
                The center of our diaphragm moves more when we take deep
                breaths: up to four inches! In the model you made, the ribcage
                (the plastic bottle) is fixed, but you can move the "diaphragm"
                more by pulling the knot farther and pushing it in more. Try it
                out. How does that change the volume of air that flows in and
                out of the lung balloon?
              </li>
              <li>
                Extra: Add a windpipe to your model. To do this take the balloon
                out of the bottle and slip its neck over a straw; secure the
                balloon to the straw with tape. Hang the balloonâ€”and a short
                section of the strawâ€”in the bottle's neck and use clay to hold
                it in place. Make sure the clay makes an airtight seal around
                the straw and the bottle neck. No change is needed to the second
                balloon that closes off the bottom of the bottle. Can you see
                which part models the windpipe?
              </li>
              <li>
                Extra: A cough is the body forcefully expelling air to get rid
                of something that caused irritation. During a cough you breathe
                in relatively deeply but instead of air flowing out while the
                chest cavity contracts, your throat closes, and air builds up in
                the lungs. When the throat opens the chest contracts even more
                and air flows out in a forceful way. Can you mimic a cough with
                your model?
              </li>
              <li>
                Extra: Find a way to create a model that includes a windpipe
                that splits into two bronchi, each with a lung attached. The
                model with a windpipe and one lung is a good start. How can you
                add a second lung? Can you find a reason why having two lungs is
                beneficial for us?
              </li>
            </ul>
            <h3>Observation and Results</h3>
            <p>
              When you pulled the knot back, the space inside the bottle
              increased and your balloon probably filled up with air. In the
              same way, when the diaphragm in our body pulls back, the chest
              cavity increases and air flows into our lungs, and we inhale.
            </p>
            <p>
              When you pushed the knot in, the space inside the bottle
              decreased, and the balloon probably deflated. In the same way,
              when the diaphragm relaxes the chest cavity decreases, and air is
              pushed out of the lungs, and we exhale.{' '}
            </p>
            <p>
              When you pulled and pushed the knot further the balloon inflated
              and deflated more. This mirrors what happens when a bigger volume
              of air is displaced when we breathe more deeply.
            </p>
            <p>
              This dynamic works because of air pressure, a measure of how hard
              air presses against objects. Air pressure increases when you
              decrease the amount of space the air hasâ€”and decreases when you
              give air more space. Close a flimsy empty plastic bottle and try
              to compress it. It is difficult! The air inside pushes back. Open
              the bottle and try to compress the bottle again. It is much
              easier. The air presses back with a much reduced force. Unless
              something blocks the movement, air will move from areas of high
              pressure to areas where the pressure is lower, and this is what
              happens when air rushes in or out of the lungs. When the chest
              cavity expands there is more space around your lungs. In this
              condition the lungs can expand, making it a low-pressure area, and
              air rushes in to balance out the difference in pressure. Then to
              breathe out the chest cavity and lungs shrink. This raises the air
              pressure in your lungs, and the air rushes back out.
            </p>
            {/* Safety */}
            <h3 id="safety">Safety First and Adult Supervision</h3>
            <ul>
              <li>Follow the experiment’s instructions carefully.</li>
              <li>A responsible adult should assist with each experiment.</li>
              <li>
                While science experiments at home are exciting ways to learn
                about science hands-on, please note that some may require
                participants to take extra safety precautions and/or make a
                mess.
              </li>
              <li>
                Adults should handle or assist with potentially harmful
                materials or sharp objects.
              </li>
              <li>
                Adult should review each experiment and determine what the
                appropriate age is for the student’s participation in each
                activity before conducting any experiment.
              </li>
            </ul>
            {/* Standards */}
            <h3 id="standards">
              Next Generation Science Standard (NGSS) Supported - Disciplinary
              Core Ideas
            </h3>
            <p>
              This experiment was selected for Science at Home because it
              teaches NGSS Disciplinary Core Ideas, which have broad importance
              within or across multiple science or engineering disciplines.
            </p>
            <p>
              Learn more about how this experiment is based in NGSS Disciplinary
              Core Ideas.
            </p>
            <p className="bold">
              Engineering Design (ETS)1: Engineering Design
            </p>
            <div className="accordion">
              <div className="accordion__group">
                <div className="accordion__item">
                  <input type="checkbox" id="chck1"></input>
                  <label className="label" for="chck1">
                    PS1.A: Structure and Properties of Matter
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>2-PS1-1.</em> Different kinds of matter exist and
                        many of them can be either solid or liquid depending on
                        temperature. Matter can be described and classified by
                        its observable properties.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>5-PS1-1.</em>
                        <ul>
                          <li>
                            Matter of any type can be subdivided into particles
                            that are too small to see, but even then, the matter
                            still exists and can be detected by other means.
                          </li>
                          <li>
                            A model shows that gases are made from matter
                            particles that are too small to see and are moving
                            freely around in space. This can explain many
                            observations, including the inflation and shape of a
                            balloon and the effects of air on larger particles
                            or objects.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <em>5-PS1-2.</em> The amount (weight) of matter is
                        conserved when it changes form, even in transitions in
                        which it seems to vanish.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS1-1.</em>
                        <ul>
                          <li>
                            Substances are made from different types of atoms,
                            which combine with one another in various ways.
                          </li>
                          <li>
                            Atoms form molecules that range in size from two to
                            thousands of atoms.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <em>MS-PS1-4.</em>
                        <ul>
                          <li>
                            Gases and liquids are made of molecules or inert
                            atoms that are moving about relative to each other.
                          </li>
                          <li>
                            In a gas, they are widely spaced except when they
                            happen to collide.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck2"></input>
                  <label className="label" for="chck2">
                    PS2.A: Forces and Motion
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>K-PS2-1.</em> Pushes and pulls can have different
                        strengths and directions.
                      </li>
                      <li>
                        <em>K-PS2-2.</em> Pushing or pulling on an object can
                        change the speed or direction of its motion and can
                        start or stop it.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>3-PS2-1.</em> Each force acts on one particular
                        object and has both strength and direction. An object
                        typically at rest has multiple forces acting on it, but
                        they add to give zero net force on the object. Forces
                        that do not sum to zero can cause changes in the
                        object's speed or direction of motion.
                      </li>
                      <li>
                        <em>3-PS2-2.</em> The patterns of an object's motion in
                        various situations can be observed and measured; when
                        that past motion exhibits a regular pattern, future
                        motion can be predicted from it.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS2-1.</em> For any pair of interacting objects,
                        the force exerted by the first object on the second
                        object is equal in strength to the force that the second
                        object exerts on the first, but in the opposite
                        direction (Newton's third law.)
                      </li>
                      <li>
                        <em>MS-PS2-2.</em> The motion of an object is determined
                        by the sum of the forces acting on it; if the total
                        force on the object is not zero, its motion will change.
                        The greater the mass of the object, the greater the
                        force needed to achieve the same change in motion. For
                        any given object, a larger force causes a larger change
                        in motion.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS2-1.</em> Newton's second law accurately
                        predicts changes in the motion of macroscopic objects.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck3"></input>
                  <label className="label" for="chck3">
                    PS2.B: Types of Interactions
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>K-PS2-1.</em> When objects touch or collide, they
                        push on one another and can change motion.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>3-PS2-1.</em> Objects in contact exert forces on
                        each other.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck4"></input>
                  <label className="label" for="chck4">
                    PS3.C: Relationship Between Energy and Forces
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>K-PS2-1.</em> A bigger push or pull makes things go
                        faster.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>4-PS3-3.</em> When objects collide, the contact
                        forces transfer energy so as to change the objects’
                        motions.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS3-2.</em> When two objects interact, each one
                        exerts a force on the other that can cause energy to be
                        transferred to or from the object.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS3-5.</em> When two objects interacting through
                        a field change relative position, the energy stored in
                        the field is changed.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck5"></input>
                  <label className="label" for="chck5">
                    PS3.D: Energy in Chemical Processes and Everyday Life
                  </label>
                  <div className="content">
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>4-PS3-4.</em> The expression "produce energy"
                        typically refers to the conversion of stored energy into
                        a desired form for practical use.
                      </li>
                      <li>
                        <em>5-PS3-1.</em> The energy released from food was once
                        energy from the Sun that was captured by plants in the
                        chemical process that forms plant matter.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck6"></input>
                  <label className="label" for="chck6">
                    LS1.A: Structure and Function
                  </label>
                  <div className="content">
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>4-LS1-1.</em> Plants and animals have both internal
                        and external structures that serve various functions in
                        growth, survival, behavior, and reproduction.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-LS1-3.</em> In multicellular organisms, the body
                        is a system of multiple interacting subsystems. These
                        subsystems are groups of cells that work together to
                        form tissues and organs that are specialized for
                        particular body functions.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck7"></input>
                  <label className="label" for="chck7">
                    LS1.C: Organization for Matter and Energy Flow in Organisms.
                  </label>
                  <div className="content">
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-LS1-6.</em> As matter and energy flow through
                        different organizational levels of living systems,
                        chemical elements are recombined in different ways to
                        form different products. The chemical reaction by which
                        plants produce complex food molecules requires an energy
                        input to occur. In this reaction, carbon dioxide and
                        water combine to form carbon-based organic molecules and
                        release oxygen.
                      </li>
                      <li>
                        <em>MS-LS1-7.</em> Cellular respiration in plants and
                        animals is a chemical process whereby the bonds of food
                        molecules and oxygen molecules are broken and new
                        compounds are formed that can transport energy to
                        muscles. Cellular respiration also releases the energy
                        needed to maintain body temperature despite ongoing
                        energy transfer to the surrounding environment.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Column>
          {/* PI team still trying to decide if we're going 
              to use these callouts prior to site launch
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="career-right-image">
                <Image filename="HowDoWeBreathe-Profile-Square.jpg" />
              </div>
              <div className="global__profile-content">
                <h3>Dakota Dozier</h3>
                <h4>Special Guest, 3M</h4>
              </div>
            </div>
          </Column> */}
        </Row>
      </Container>
    </Section>
    {/* Explore Additional Science at Home Videos and Activities */}
    <Section className="science-at-home__cards pb-6">
      <Container>
        <Row>
          <Column fullWidth>
            <h2>Explore Additional Science at Home Videos and Activities</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="SoapBoat-Thumbnail.png" />
              <div className="card__content">
                <h3>Soap Boat</h3>
                <h4>
                  Join 3M Researcher Vasav Shani as he introduces you to the
                  science of surface tension.
                </h4>
                <p>
                  Not only is it only important for many engineering and earth
                  science processes, it also makes blowing bubbles possible.
                </p>
              </div>
              <Link to="../soap-boat" className="button bg-gradient-purple">
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="BernoulliBalance-Thumbnail.png" />
              <div className="card__content">
                <h3>Bernoulli Balance</h3>
                <h4>
                  A jetliner can weigh over 300 tons, so why doesn’t it just
                  drop out of the air?
                </h4>
                <p>
                  3M’s SVP of Corporate Affairs, Denise Rutherford explains the
                  Bernoulli principle and how without it, planes (and birds)
                  couldn’t fly.
                </p>
              </div>
              <Link
                to="../bernoulli-balance"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="PushAndPull-Thumbnail.png" />
              <div className="card__content">
                <h3>Push and Pull</h3>
                <p>
                  Follow along with 3M’s Sam Reiss, as he shows you that
                  magnetism is more than just a simple push and pull – it’s an
                  example of the power of the earth itself.
                </p>
              </div>
              <Link to="../push-and-pull" className="button bg-gradient-purple">
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default HowDoWeBreathe;
